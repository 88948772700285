<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    if(this.$store.state.user.access_token) {
      this.$store.dispatch('getUserInfo')
    }
  }
}
</script>
<style lang="scss">
html{
  -webkit-tap-highlight-color:rgba(0,0,0,0); 
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/*滚动条宽高 */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* 滚动条上的滚动滑块。样式 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  /* 鼠标悬停时，设置滑块的背景颜色为深灰色 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  /* 鼠标按下时，设置滑块的背景颜色为灰色 */
  ::-webkit-scrollbar-thumb:active {
    background-color: #888;
  }
</style>
