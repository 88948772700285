import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        redirect: '/home',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/layout/LayoutView.vue'),
        children: [
            {
                path: 'home',
                name: 'home',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/home/home.vue')
            },
            {
                path: 'class',
                name: 'class',
                meta: {
                    needLogin: true,
                },
                component: () => import('@/views/accompanyClass/ClassView.vue') //课程列表
            },
            {
                path: 'classDetail',
                name: 'classDetail',
                meta: {
                    needLogin: true,
                },
                component: () => import('@/views/accompanyClass/ClassDetail.vue') //课程详情
            },
            {
                path: 'classPlay',
                name: 'classPlay',
                meta: {
                    needLogin: true,
                },
                component: () => import('@/views/accompanyClass/ClassPlay.vue') //课程详情播放页
            },
        ]
    },
    {
        path: '/mobile',
        name: 'mobile',
        redirect: '/mobile/downLoad',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/mobile/mobile.vue'),
        children: [
            {
                // 下载页面
                path: 'downLoad',
                name: 'downLoad',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/downLoad/downLoad.vue')
            },
            {
                // 用户端邀请好友规则页面
                path: 'userInviteRule',
                name: 'userInviteRule',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/userInviteRule/userInviteRule.vue')
            },
            {
                // 陪诊端邀请好友规则页面
                path: 'accompanyInviteRule',
                name: 'accompanyInviteRule',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/accompanyInviteRule/accompanyInviteRule.vue')
            },
            {
                // 信用分规则页面
                path: 'creditScoreRule',
                name: 'creditScoreRule',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/creditScoreRule/creditScoreRule.vue')
            },
            {
                // 用户协议页面
                path: 'userAgreement',
                name: 'userAgreement',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/userAgreement/userAgreement.vue')
            },
            {
                // 隐私政策页面
                path: 'privacyPolicy',
                name: 'privacyPolicy',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/privacyPolicy/privacyPolicy.vue')
            },
            {
                // 平台免责协议页面
                path: 'platformAgreement',
                name: 'platformAgreement',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/platformAgreement/platformAgreement.vue')
            },
            {
                // h5宣传图
                path: 'promotional',
                name: 'promotional',
                meta: {
                    needLogin: false,
                },
                component: () => import('@/views/mobile/promotionalImage/promotionalImage.vue')
            },
        ]
    },
    {
        path: '/agreement/privacy',
        name: 'privacy',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/agreement/privacyPolicy.vue') // 隐私政策
    },
    {
        path: '/agreement/userAgreement',
        name: 'userAgreement',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/agreement/userAgreement.vue') // 用户协议
    },
    {
        path: '*',
        name: '404',
        meta: {
            needLogin: false,
        },
        component: () => import(/* webpackChunkName: "NotFoundView" */ '@/views/NotFoundView.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
                y: 0,
            }
        }else if(savedPosition) {
            return savedPosition;
        }else {
            return {x: 0, y: 0}
            
        }
    },
})

export default router
